import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InstagramIcon from "../../images/icons/instagram.svg"

const Instagram = () => {
  const data = useStaticQuery(graphql`
    query InstagramUrlQuery {
      site {
        siteMetadata {
          social {
            instagram
          }
        }
      }
    }
  `)

  return (
    <a
      className="icon"
      target="_blank"
      rel="noopener noreferrer"
      href={data.site.siteMetadata.social.instagram}
    >
      <InstagramIcon />
    </a>
  )
}

export default Instagram
