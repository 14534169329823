import React from "react"
import MenuSVG from "../../images/icons/menu.svg"

const Menu = ({ onClick }) => (
  <button className="btn btn--icon" onClick={onClick}>
    <MenuSVG />
  </button>
)

export default Menu
