import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames"
import Next from "../icons/next"
import Previous from "../icons/previous"

import "./footer.less"

const Footer = ({ secondaryFooter, previousPage, nextPage }) => {
  return (
    <footer
      className={classNames({
        bg_secondary: secondaryFooter,
      })}
    >
      <div className="footer__inner">
        <div className="footer__previous-page">
          {previousPage && (
            <Link to={previousPage.path}>
              <div className="link">
                <Previous />
                <p>Previous</p>
              </div>
            </Link>
          )}
        </div>

        <span>X</span>

        <div className="footer__next-page">
          {nextPage && (
            <Link to={nextPage.path}>
              <div className="link">
                <p>Next</p>
                <Next />
              </div>
            </Link>
          )}
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  secondaryFooter: false,
  previousPage: null,
  nextPage: null,
}

Footer.propTypes = {
  secondaryFooter: PropTypes.bool,
  previousPage: PropTypes.shape({}), // TODO
  nextPage: PropTypes.shape({}), // TODO
}

export default Footer
