import React from "react"
import PreviousSVG from "../../images/icons/previous.svg"

const Previous = () => (
  <div className="previous">
    <PreviousSVG />
  </div>
)

export default Previous
