import React from "react"
import CloseSVG from "../../images/icons/close.svg"

const Close = ({ onClick }) => (
  <button className="btn btn--icon" onClick={onClick}>
    <CloseSVG />
  </button>
)

export default Close
