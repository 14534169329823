import React from "react"
import { Link } from "gatsby"
import LogoSVG from "../../images/icons/logo.svg"

const Logo = ({ alt }) => (
  <Link className="logo" to="/" alt={alt}>
    <LogoSVG />
  </Link>
)

export default Logo
