import React from "react"
import NextSVG from "../../images/icons/next.svg"

const Next = () => (
  <div className="next">
    <NextSVG />
  </div>
)

export default Next
