import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import SEO from "../seo"
import Header from "../header/header"
import Footer from "../footer/footer"
import "./layout.less"

const Layout = ({
  title,
  description,
  featuredImagePath,
  secondaryHeader,
  secondaryMain,
  previousPage,
  nextPage,
  className,
  children,
  skeleton,
}) => {
  return (
    <>
      {!skeleton && (
        <SEO
          title={title}
          description={description}
          featuredImagePath={featuredImagePath}
        />
      )}
      {!skeleton && <Header secondaryHeader={secondaryHeader} />}
      <main
        className={classNames("main-layout", className, {
          bg_secondary: secondaryMain,
        })}
      >
        {children}
      </main>
      {!skeleton && (
        <Footer
          previousPage={previousPage}
          nextPage={nextPage}
          secondaryFooter={secondaryMain}
        />
      )}
    </>
  )
}

Layout.defaultProps = {
  secondaryHeader: false,
  secondaryMain: false,
  className: "",
  skeleton: false,
  previousPage: null,
  featuredImagePath: null,
  nextPage: null,
  description: null,
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  featuredImagePath: PropTypes.string,
  children: PropTypes.node.isRequired,
  secondaryHeader: PropTypes.bool,
  secondaryMain: PropTypes.bool,
  className: PropTypes.string,
  skeleton: PropTypes.bool,
  previousPage: PropTypes.shape({}), // TODO
  nextPage: PropTypes.shape({}), // TODO
}
export default Layout
